import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect } from 'react';
export default function SmartContractConnect() {
  useEffect(() => {
    document.title = 'Smart Contract API';
  })
  return (
    <Container>
      <Row>
        <Col>
          <h2>Under Development</h2>
        </Col>
      </Row>
    </Container>
  )
}