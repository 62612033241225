import React from 'react';
import ReactDOM from 'react-dom/client';
import { 
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import './index.css';
import App from './App';
import './App.css';
import reportWebVitals from './reportWebVitals';
import GweiCalculator from "./pages/GweiCalculator";
import SmartContractConnect from "./pages/SmartContractConnect";
import Container from 'react-bootstrap/Container';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Container>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="gwei-calculator" element={<GweiCalculator />} />
          <Route path="smart-contract-connect" element={<SmartContractConnect />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </Container>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
