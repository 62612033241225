import Container from 'react-bootstrap/Container';
import { ethers } from "ethers";
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import React, { useEffect } from 'react';
export default function GweiCalculator() {
  useEffect(() => {
    document.title = 'Gwei Calculator and Wei Converter - Ethereum Tools';
  })
  const [weiInput, setWeiInput] = useState("");
  const [gWeiInput, setGweiInput] = useState("");
  const [etherInput, setEtherInput] = useState("");

  const toFixed = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
      }
    }
    return x;
  }


  const handleChangeWeiInput = (value) => {

    if (value !== "" && value !== 0) {
      const ethValue = ethers.utils.formatEther(value);
      const gweiValue = ethers.utils.formatUnits(value, "gwei");
      setEtherInput(ethValue)
      setGweiInput(toFixed(parseInt(gweiValue)))
    } else {
      setGweiInput(0)
      setEtherInput(0)
    }
    setWeiInput(value)
  }

  const handleChangeGweiInput = (value) => {

    if (value !== "" && value !== 0) {
      const weiValue = ethers.utils.parseUnits(value, 'gwei')
      const ethValue = ethers.utils.formatEther(weiValue);
      setWeiInput(toFixed(parseInt(weiValue)))
      setEtherInput(ethValue)
    } else {
      setWeiInput(0)
      setEtherInput(0)
    }

    setGweiInput(value)
  }

  const handleChangeEtherInput = (value) => {
    if (value !== "") {
      let wei = ethers.utils.parseEther(value);
      const gweiValue = ethers.utils.formatUnits(wei, "gwei");
      setWeiInput(wei.toString())
      setGweiInput(toFixed(parseInt(gweiValue)))
      console.log(parseInt(gweiValue))
    } else {
      setWeiInput(0)
      setGweiInput(0)
    }

    setEtherInput(value)
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value)
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copied
    </Tooltip>
  );

  return (
    <div>
      <Container>
        <br></br> <br></br>
        <h2>Gwei Calculator and Wei Converter</h2>
        <br></br>
        <Form>

          <Form.Group className="mb-3">
            <Form.Label>Wei</Form.Label>
            <InputGroup className="mb-3">
              <Button onClick={
                async () => {
                  setWeiInput(await navigator.clipboard.readText())
                  handleChangeWeiInput(await navigator.clipboard.readText())
                }
              } variant="dark" >
                Paste
              </Button>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}>
                <Button id='1' onClick={() => handleCopy(weiInput)} variant="secondary" >
                  Copy
                </Button>
              </OverlayTrigger>
              <Form.Control placeholder="Wei Uints" value={weiInput} onChange={(e) => handleChangeWeiInput(e.target.value)} />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Gwei</Form.Label>
            <InputGroup className="mb-3">
              <Button onClick={
                async () => {
                  setGweiInput(await navigator.clipboard.readText())
                  handleChangeGweiInput(await navigator.clipboard.readText())
                }} variant="dark" >
                Paste
              </Button>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip} >
                <Button id='2' onClick={() => handleCopy(gWeiInput)} variant="secondary" >
                  Copy
                </Button>
              </OverlayTrigger>
              <Form.Control placeholder="Gwei Uints" value={gWeiInput} onChange={(e) => handleChangeGweiInput(e.target.value)} />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Ether</Form.Label>
            <InputGroup className="mb-3">
              <Button onClick={
                async () => {
                  setEtherInput(await navigator.clipboard.readText())
                  handleChangeEtherInput(await navigator.clipboard.readText())
                }} variant="dark" >
                Paste
              </Button>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip} >
                <Button id='3' onClick={() => handleCopy(etherInput)} variant="secondary" >
                  Copy
                </Button>
              </OverlayTrigger>
              <Form.Control placeholder="Ether Uints" value={etherInput} onChange={(e) => handleChangeEtherInput(e.target.value)} />
            </InputGroup>
          </Form.Group>
        </Form>
      </Container>
    </div>
  );
}